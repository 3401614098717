<template>
  <div class="min-h-screen bg-white flex flex-col">
    <!-- Header/AppBar -->
    <header class="bg-[#273D5C] shadow-md px-4 py-2">
      <div class="flex items-center justify-between">
        <!-- Logo und Titel -->
        <div class="flex items-center space-x-2 cursor-pointer" @click="$router.push('/')" role="button">
          <img class="h-8 w-auto" alt="Logo" src="@/assets/logo.svg" />
          <span class="text-white text-xl font-medium">{{ $t('listenerView.header.title') }}</span>
        </div>

        <!-- Sprach-Dropdown -->
        <div class="relative">
          <button @click="isLanguageMenuOpen = !isLanguageMenuOpen"
            class="flex items-center space-x-2 text-white hover:bg-[#32497a] px-3 py-1.5 rounded-md transition-colors">
            <img :src="require(`@/assets/flags/${$i18n.locale}.svg`)" :alt="$i18n.locale" class="w-5 h-4 rounded" />
            <span class="hidden sm:inline">{{ getCurrentLanguageName }}</span>
            <svg class="w-4 h-4 transition-transform" :class="{ 'rotate-180': isLanguageMenuOpen }" fill="none"
              stroke="currentColor" viewBox="0 0 24 24">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
          </button>

          <!-- Dropdown Menu -->
          <div v-if="isLanguageMenuOpen"
            class="absolute right-0 mt-2 w-fit pr-6 bg-white rounded-md shadow-lg py-1 z-50">
            <button v-for="lang in languages" :key="lang.locale" @click="changeLanguage(lang.locale)"
              class="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              :class="{ 'bg-gray-50': $i18n.locale === lang.locale }">
              <img :src="require(`@/assets/flags/${lang.locale}.svg`)" :alt="lang.locale"
                class="w-5 h-4 rounded-sm mr-3" />
              {{ lang.name }}
            </button>
          </div>
        </div>
      </div>
    </header>



    <!-- Main Content -->
    <main class="flex-1 container mx-auto px-4 py-5 overflow-auto">
      <!-- Conference Name -->
      <h1 class="text-2xl md:text-3xl font-bold text-gray-900 text-center mb-6">
        {{ conferenceName }}
      </h1>

      <!-- Info Banner with Popup -->
      <div class="mb-6 bg-blue-50 border border-blue-200 rounded-lg p-3 flex items-center justify-between">
        <div class="flex items-center">
          <svg class="w-5 h-5 text-blue-600 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <span class="text-blue-700">{{ $t('listenerView.infoBanner.keepPageOpen') }}</span>
        </div>
        <div class="relative" @mouseenter="showInfoPopup = true" @mouseleave="showInfoPopup = false">
          <svg class="w-5 h-5 text-blue-600 cursor-help" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
              d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          <!-- Info Popup -->
          <div v-if="showInfoPopup"
            class="absolute bottom-full right-5 top-0 h-fit w-72 p-4 bg-white rounded-lg shadow-lg border border-gray-200 text-sm z-50">
            <p class="text-gray-700 mb-2">
              {{ $t('listenerView.infoBanner.tooltip.title') }}
            </p>
            <p class="text-gray-700">
              {{ $t('listenerView.infoBanner.tooltip.tip') }}
            </p>
          </div>
        </div>
      </div>

      <!-- Available Languages Section -->
      <div class="mb-4">
        <h2 class="text-lg font-semibold text-gray-900 px-2"> {{ $t('listenerView.content.availableLanguages') }}
        </h2>
      </div>

      <!-- Language Buttons -->
      <div class="space-y-3">
        <div v-for="(button, index) in displayedButtons" :key="button.id" class="w-full">
          <button @click="handleButtonClick(button, index)" class="w-full p-4 rounded-xl transition-all duration-200 shadow hover:shadow-md 
                         bg-white border cursor-pointer" :class="[
                          button.isPlaying ? 'border-2 border-blue-500' : 'border-gray-200',
                        ]">
            <div class="flex items-center">
              <!-- Play/Pause Icon -->
              <div :class="[
                'w-12 h-12 rounded-full flex items-center justify-center mr-4',
                button.isPlaying ? 'bg-blue-500' : 'bg-gray-100'
              ]">
                <svg v-if="button.isPlaying" class="w-6 h-6 text-white" fill="none" viewBox="0 0 24 24"
                  stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <svg v-else class="w-6 h-6 text-blue-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>

              <!-- Channel Info -->
              <div class="flex-1">
                <div class="text-left font-medium text-gray-900">
                  {{ button.channelName }}
                </div>
                <!-- Status Messages -->
                <div class="flex flex-wrap gap-2 mt-1">
                  <div v-if="button.isPlaying" class="text-sm text-blue-500">
                    {{ $t('listenerView.content.status.playing') }}
                  </div>
                  <!-- Status Badges -->
                  <div class="flex flex-wrap gap-2">
                    <!-- Conference Ended Badge -->
                    <span v-if="recordingStatus === 'ended'"
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">
                      <svg class="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                      </svg>
                      {{ $t('listenerView.content.status.conferenceBadges.inactive') }}
                    </span>

                    <!-- AI Translation Paused Badge -->
                    <span v-if="button.useAI && recordingStatus === 'paused'"
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800">
                      <svg class="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 9v6m4-6v6" />
                      </svg>
                      {{ $t('listenerView.content.status.conferenceBadges.translationPaused') }}
                    </span>

                    <!-- Speaker Muted Badge -->
                    <span v-if="!button.useAI && button.isMuted"
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800">
                      <svg class="w-3 h-3 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M5.586 15H4a1 1 0 01-1-1v-4a1 1 0 011-1h1.586l4.707-4.707C10.923 3.663 12 4.109 12 5v14c0 .891-1.077 1.337-1.707.707L5.586 15z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M17 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2" />
                      </svg>
                      {{ $t('listenerView.content.status.conferenceBadges.speakerMuted') }}
                    </span>

                    <!-- Waiting for Translation Badge -->
                    <span v-if="button.useAI && shouldShowWaitingBadge(button)"
                      class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                      <svg class="w-3 h-3 mr-1 slow-spin" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" />
                      </svg>
                      {{ $t('listenerView.content.status.conferenceBadges.waitingForTranslation') }}
                    </span>
                  </div>
                </div>
              </div>

              <!-- Audio Wave Animation -->
              <div v-if="button.isPlaying" class="flex items-center space-x-1">
                <!-- Show different animation states based on status -->
                <template
                  v-if="(button.useAI && recordingStatus === 'paused') || (!button.useAI && button.isMuted) || recordingStatus === 'ended'">
                  <div class="w-1 h-2 bg-gray-300 rounded-full mx-0.5" v-for="i in 3" :key="i"></div>
                </template>
                <template v-else>
                  <div class="sound-wave"></div>
                  <div class="sound-wave delay-750"></div>
                  <div class="sound-wave delay-1500"></div>
                </template>
              </div>
            </div>
          </button>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <footer class="bg-white py-4 px-6 border-t">
      <div class="flex flex-col items-center justify-center space-y-2">
        <span class="text-xs text-gray-500">{{ $t('listenerView.footer.poweredBy') }}</span>
        <div class="flex items-center space-x-2 cursor-pointer" @click="$router.push('/')">
          <img class="h-8 w-auto" alt="Logo" src="@/assets/logo.svg" />
          <span class="text-xl font-medium text-gray-900">Streamlingo</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, onValue, get, child } from 'firebase/database';
import { rtdb, functions } from '@/firebaseInit';
import { httpsCallable } from 'firebase/functions';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { markRaw } from 'vue';


export default {
  data() {
    return {
      showInfoPopup: false,
      isLanguageMenuOpen: false,
      languages: [
        { locale: 'de', name: 'Deutsch' },
        { locale: 'en', name: 'English' }
      ],
      conferenceId: null,
      recordingStatus: "ended",
      audioUrls: [],
      currentAudio: null,
      conferenceName: '',
      displayedButtons: [],
      originalAudio: null,
      currentAudioIndex: 0,
      isAudioPlaying: false,
      channelIdIsPlaying: null,
      channels: [],
      client: markRaw(AgoraRTC.createClient({ mode: "rtc", codec: "h264" })),
      uid: Math.floor(Math.random() * 100000),
      generateRtcToken: httpsCallable(functions, 'generateRtcToken'),
      changeListenerCount: httpsCallable(functions, 'changeListenerCount'),
    };
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    document.addEventListener('click', this.handleClickOutside);
  },
  async created() {
    const conferenceUrl = this.$route.params.conferenceUrl;
    const snapshot = await get(child(ref(rtdb), `urlMapping/${conferenceUrl}/conferenceId`));
    this.conferenceId = snapshot.val() ? snapshot.val() : conferenceUrl;

    this.watchRecordingStatus();
    this.loadInitialHistoricalUrls();
    this.setupCurrentUrlListener();
    this.generateRtcToken({ reduceColdStart: true });
    this.changeListenerCount({ reduceColdStart: true });
  },
  watch: {
    recordingStatus: {
      handler: async function (newVal) {
        if (newVal === 'ended') {
          if (this.isAudioPlaying) {
            await this.stopAudio();
            this.isAudioPlaying = false;
            this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
          }
          this.audioUrls = [];
        }
      },
    },

  },
  computed: {
    getCurrentLanguageName() {
      const currentLang = this.languages.find(lang => lang.locale === this.$i18n.locale);
      return currentLang ? currentLang.name : '';
    }
  },
  methods: {
    shouldShowWaitingBadge(button) {
      return (
        this.recordingStatus === 'running' &&
        (!this.audioUrls[button.targetLanguage.code] ||
          this.audioUrls[button.targetLanguage.code].length === 0)
      );
    },
    handleClickOutside(event) {
      const dropdown = this.$el.querySelector('.relative');
      if (dropdown && !dropdown.contains(event.target)) {
        this.isLanguageMenuOpen = false;
      }
    },
    changeLanguage(locale) {
      this.$i18n.locale = locale;
      this.isLanguageMenuOpen = false;
    },
    watchRecordingStatus() {
      const statusRef = ref(rtdb, `conferences/${this.conferenceId}`);
      onValue(statusRef, async (snapshot) => {
        if (snapshot.exists()) {
          this.conferenceName = snapshot.val().conferenceName;
          this.recordingStatus = snapshot.val().recordingStatus;

          const newChannels = snapshot.val().channels || [];
          const areChannelsEqual = JSON.stringify(this.channels) === JSON.stringify(newChannels);
          if (!areChannelsEqual) {
            if (this.isAudioPlaying) {
              const isCurrentActiveChannel = this.channels.find(channel => channel.id === this.channelIdIsPlaying);
              const newCurrentActiveChannel = newChannels.find(channel => channel.id === this.channelIdIsPlaying);
              if (JSON.stringify(isCurrentActiveChannel) === JSON.stringify(newCurrentActiveChannel)) {
                this.channels = newChannels;
                this.updateDisplayedButtons();
                // setzte channel mit this.channelIdIsPlaying auf isPlaying
                this.displayedButtons = this.displayedButtons.map(button => ({
                  ...button,
                  isPlaying: button.id === this.channelIdIsPlaying,
                }));
              } else {
                await this.stopAudio();
                this.isAudioPlaying = false;
                this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
                this.channelIdIsPlaying = null;
                this.channels = newChannels;
                this.updateDisplayedButtons();

              }
            } else {
              this.channels = newChannels;
              this.updateDisplayedButtons();
            }
          }
        } else {
          console.log('Keine Daten verfügbar');
        }
      }, (error) => {
        console.error(error);
      });
    },
    loadInitialHistoricalUrls() {
      if (this.recordingStatus === 'ended') return; // Wenn die Aufnahme beendet ist, werden keine historischen URLs benötigt
      const historicalUrlsRef = ref(rtdb, `translations/${this.conferenceId}/historicalUrls`);
      get(historicalUrlsRef).then((snapshot) => {
        if (snapshot.exists()) {
          const allUrls = snapshot.val();
          Object.keys(allUrls).forEach(language => {
            // Extrahiere die URL-Werte aus den Objekten und speichere sie in einem Array
            const urlArray = Object.values(allUrls[language]).map(item => item);
            this.audioUrls[language] = urlArray;
          });
        } else {
          // Keine historischen URLs gefunden, leere Struktur initialisieren
          Object.keys(this.audioUrls).forEach(language => {
            this.audioUrls[language] = [];
          });
        }
      }).catch((error) => {
        console.error('Error loading historical URLs:', error);
      });
    },
    setupCurrentUrlListener() {
      const currentUrlRef = ref(rtdb, `translations/${this.conferenceId}/currentUrl`);
      onValue(currentUrlRef, (snapshot) => {
        if (snapshot.exists() && this.recordingStatus !== 'ended') {
          const currentUrls = snapshot.val();
          Object.keys(currentUrls).forEach(language => {
            const newUrl = currentUrls[language];
            if (!this.audioUrls[language]) {
              this.audioUrls[language] = [];
            }
            const lastUrl = this.audioUrls[language].length > 0 ? this.audioUrls[language][this.audioUrls[language].length - 1] : null;
            if (newUrl !== lastUrl) {
              this.audioUrls[language].push(newUrl);
            }
          });
        }
      });
    },
    updateDisplayedButtons() {
      this.displayedButtons = this.channels
        .filter(channel => channel.isActive)
        .map(channel => ({
          id: channel.id,
          channelName: channel.channelName,
          isPlaying: channel.isPlaying ? channel.isPlaying : false,
          useAI: channel.useAI,
          isMuted: channel.isMuted,
          speakerStreamCode: channel.speakerStreamCode,
          targetLanguage: channel.targetLanguage,
        }));
    },
    isButtonDeactivated(buttonId) {
      const button = this.displayedButtons.find(b => b.id === buttonId);
      if (button.useAI) {
        return (
          (this.recordingStatus !== 'running' && this.currentAudio === null) ||
          !this.audioUrls[button.targetLanguage.code] ||
          this.audioUrls[button.targetLanguage.code].length === 0
        );
      } else {
        return button.isMuted || this.recordingStatus === 'ended'; // Überprüfe isMuted für nicht-AI-Buttons
      }
    },
    async handleButtonClick(button, index) {
      await this.stopAudio();

      if (button.useAI && this.isButtonDeactivated(button.id)) return;
      if (!button.useAI && button.isMuted) return;

      if (this.isAudioPlaying) {
        this.isAudioPlaying = false;
        this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
        this.channelIdIsPlaying = null;
        return;
      }
      this.isAudioPlaying = true;
      this.channelIdIsPlaying = button.id;
      this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: true });

      if (button.useAI) {
        this.playFromPenultimate(button.targetLanguage.code, index);
      } else {
        const isPublisher = false;
        const channelName = button.speakerStreamCode.toString();
        const response = await this.generateRtcToken({ channelName: channelName, isPublisher: isPublisher })
          .catch((error) => { console.error('Error:', error.message); });
        await this.client.join(response.data.appId, channelName, response.data.token, response.data.uid);

        this.client.on("user-published", async (user, mediaType) => {
          await this.client.subscribe(user, mediaType);
          if (mediaType === "audio") {
            const audioTrack = user.audioTrack;
            audioTrack.play();
          }
        });

        this.displayedButtons = this.displayedButtons.map((button, buttonIndex) => ({
          ...button,
          isPlaying: buttonIndex === index,
        }));
      }

    },
    async playFromPenultimate(languageCode, index) {
      let languageObj = this.audioUrls[languageCode];
      if (!languageObj || languageObj.length === 0) return;
      if (languageObj.length >= 2) {
        this.currentAudioIndex = languageObj.length - 2;
      } else {
        this.currentAudioIndex = 0;
      }

      this.displayedButtons = this.displayedButtons.map((button, buttonIndex) => ({
        ...button,
        isPlaying: buttonIndex === index,
      }));

      this.playNextAudio(languageCode);
    },
    async playNextAudio(languageCode) {
      let languageObj = this.audioUrls[languageCode];
      if (this.currentAudioIndex >= languageObj.length) {
        if (this.recordingStatus !== 'ended') {
          // Wenn keine weiteren Audiodateien verfügbar sind, aber die Live-Übersetzung aktiv ist, spiele das stumme Audio ab
          this.currentAudio = new Audio(this.$SILENT_AUDIO_URL);
          await this.currentAudio.play();
          this.currentAudio.onended = () => {
            // Versuche erneut, das nächste Audio abzuspielen, nachdem das stumme Audio beendet ist
            this.playNextAudio(languageCode);
          };
        } else {
          await this.stopAudio();
        }
        return;
      }

      this.currentAudio = new Audio(languageObj[this.currentAudioIndex]);
      try {
        await this.currentAudio.play();
      } catch (error) {
        console.error('Error playing audio file:', error);
        // Move to the next audio if there's an error
        this.currentAudioIndex++;
        this.playNextAudio(languageCode);
        return;
      }
      this.currentAudio.onended = () => {
        this.currentAudioIndex++;
        this.playNextAudio(languageCode); // Spiele das nächste Audio ab, wenn das aktuelle beendet ist
      };
      this.currentAudio.onerror = () => {
        console.error('Fehler beim Abspielen der Audiodatei');
        this.currentAudioIndex++;
        this.playNextAudio(languageCode); // Versuche, das nächste Audio abzuspielen, wenn ein Fehler auftritt
      };
    },
    async stopAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause(); // Stoppt die Wiedergabe des aktuellen Audios
        this.currentAudio.currentTime = 0; // Setzt die aktuelle Wiedergabezeit auf den Anfang zurück
        this.currentAudio = null; // Entfernt die Referenz auf das aktuelle Audio-Objekt
      }
      this.currentAudioIndex = 0; // Zurücksetzen des Index für das nächste Mal

      this.displayedButtons = this.displayedButtons.map(button => ({
        ...button,
        isPlaying: false,
      }));

      await this.client.leave();
    },
    fastStopAudio() {
      if (this.currentAudio) {
        this.currentAudio.pause(); // Stoppt die Wiedergabe des aktuellen Audios
        this.currentAudio.currentTime = 0; // Setzt die aktuelle Wiedergabezeit auf den Anfang zurück
        this.currentAudio = null; // Entfernt die Referenz auf das aktuelle Audio-Objekt
      }
      this.currentAudioIndex = 0; // Zurücksetzen des Index für das nächste Mal

      this.displayedButtons = this.displayedButtons.map(button => ({
        ...button,
        isPlaying: false,
      }));

      this.client.leave();
    },
    handleBeforeUnload() {
      console.log('ListenerView beforeunload')
      if (this.isAudioPlaying) {
        this.changeListenerCount({ conferenceId: this.conferenceId, channelId: this.channelIdIsPlaying, increase: false });
        this.stopAudio();
      }
    },
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    document.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style scoped>
.slow-spin {
  animation: spin 3s linear infinite;
  /* Standard ist 1s, hier auf 3s verlangsamt */
}

.sound-wave {
  width: 4px;
  height: 10px;
  background-color: #3B82F6;
  border-radius: 9999px;
  animation: soundwave 2.25s ease-in-out infinite;
  position: relative;
  transform-origin: center;
  margin: 12px 0;
}

.delay-750 {
  animation-delay: 750ms;
}

.delay-1500 {
  animation-delay: 1500ms;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@keyframes soundwave {

  0%,
  100% {
    transform: scaleY(1);
  }

  50% {
    transform: scaleY(2.2);
  }
}
</style>