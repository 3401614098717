<template>
    <div class="signup-modal">
        <div class="flex justify-between items-center mb-4">
            <h2 class="text-2xl font-bold">{{ $t('signup.title') }}</h2>
            <button @click="$emit('close')" class="text-gray-500 hover:text-gray-700">
                <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12">
                    </path>
                </svg>
            </button>
        </div>
        <form @submit.prevent="signup">
            <div class="mb-4">
                <label for="name" class="block text-gray-700 text-sm font-bold mb-2">
                    {{ $t('signup.name.label') }}
                </label>
                <input v-model="name" type="text" id="name"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    :placeholder="$t('signup.name.placeholder')" required>
            </div>
            <div class="mb-4">
                <label for="email" class="block text-gray-700 text-sm font-bold mb-2">
                    {{ $t('signup.email.label') }}
                </label>
                <input v-model="email" type="email" id="email"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    :placeholder="$t('signup.email.placeholder')" required>
            </div>
            <div class="mb-4">
                <label for="congregation" class="block text-gray-700 text-sm font-bold mb-2">
                    {{ $t('signup.congregation.label') }}
                </label>
                <input v-model="congregation" type="text" id="congregation"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    :placeholder="$t('signup.congregation.placeholder')">
            </div>
            <div class="mb-4">
                <label for="password" class="block text-gray-700 text-sm font-bold mb-2">
                    {{ $t('signup.password.label') }}
                </label>
                <input v-model="password" type="password" id="password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    :placeholder="$t('signup.password.placeholder')" required>
            </div>
            <div class="mb-6">
                <label for="confirm-password" class="block text-gray-700 text-sm font-bold mb-2">
                    {{ $t('signup.confirmPassword.label') }}
                </label>
                <input v-model="confirmPassword" type="password" id="confirm-password"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    :placeholder="$t('signup.confirmPassword.placeholder')" required>
            </div>
            <div class="mb-4">
                <label class="flex items-center">
                    <input v-model="needsSupport" type="checkbox" class="form-checkbox h-5 w-5 text-indigo-600">
                    <span class="ml-2 text-gray-700">{{ $t('signup.support.checkbox') }}</span>
                </label>
            </div>
            <!-- Telefonnummer (erscheint nur wenn Unterstützung gewünscht) -->
            <div v-if="needsSupport" class="mb-4">
                <label for="phone" class="block text-gray-700 text-sm font-bold mb-2">
                    {{ $t('signup.support.phone.label') }}
                </label>
                <input v-model="phone" type="tel" id="phone"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    :placeholder="$t('signup.support.phone.placeholder')">
                <p class="mt-1 text-sm text-gray-500">{{ $t('signup.support.phone.format') }}</p>
            </div>
            <div class="mb-4">
                <label class="flex items-center">
                    <input v-model="acceptTerms" type="checkbox" class="form-checkbox h-5 w-5 text-indigo-600" required>
                    <span class="ml-2 text-gray-700">{{ $t('signup.terms.text') }} <a href="/terms-and-conditions"
                            target="_blank" class="text-indigo-600 hover:text-indigo-800 cursor-pointer">{{
                                $t('signup.terms.link') }}</a></span>
                </label>
            </div>
            <div class="flex items-center justify-between">
                <button type="submit"
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full flex items-center justify-center"
                    :disabled="isLoading">
                    <template v-if="!isLoading">
                        {{ $t('signup.button') }}
                    </template>
                    <template v-else>
                        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
                            fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4">
                            </circle>
                            <path class="opacity-75" fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                            </path>
                        </svg>
                        {{ $t('signup.processing') }}
                    </template>
                </button>
            </div>
        </form>
        <div class="mt-4 text-center">
            <p class="text-sm">
                {{ $t('signup.haveAccount') }}
                <a @click="$emit('switch-to-login')"
                    class="font-medium text-indigo-600 hover:text-indigo-500 cursor-pointer">{{
                        $t('signup.login')
                    }}</a>
            </p>
        </div>
    </div>
</template>

<script>
import { authErrors } from '../firebaseErrorMapping';

export default {
    data() {
        return {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false,
            congregation: '',
            needsSupport: false,
            phone: '',
            isLoading: false
        }
    },
    methods: {
        async signup() {
            if (this.isLoading) return;

            try {
                this.isLoading = true;

                if (this.password !== this.confirmPassword) {
                    this.$store.dispatch('showSnackbar', { message: this.$t('signup.errors.passwordMatch') });
                    return;
                }
                if (!this.acceptTerms) {
                    this.$store.dispatch('showSnackbar', { message: this.$t('signup.errors.termsRequired') });
                    return;
                }
                if (this.needsSupport && !this.phone) {
                    this.$store.dispatch('showSnackbar', { message: this.$t('signup.errors.phoneRequired') });
                    return;
                }

                const signupData = {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    congregation: this.congregation,
                    needsSupport: this.needsSupport,
                    phone: this.needsSupport ? this.phone : null,
                };

                await this.$store.dispatch('signUp', signupData);
                this.$emit('success');
            } catch (error) {
                this.$store.dispatch('showSnackbar', {
                    message: authErrors[error.code.replace('auth/', '')] || 'An unknown error occurred.'
                });
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>